import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomAvatar from "../../UI/CustomAvathar";
import { useSelector } from "react-redux";
import { GrannyTip } from "../../UI/GrannyTip";
import { getDirectRelation } from "../../api/getDirectRelation";
import { t } from "i18next";
import {
  chooseGenderFromValue,
  createNewLifografConfig,
} from "../../utils/functions";
import { InvitePeople } from "../atoms/InvitePeople";
import { CustomDialogSwipeUp } from "../../UI/CustomDialogSwipeUp";
import { createRelationDescription } from "../../utils/Theme";
import { DeleteEntryPromt } from "../atoms/DeleteEntryPromt";

export const ChooseRelationship = ({
  relatedTo,
  relationship,
  close = () => {},
  setRelationship = () => {},
  setRelatedTo = () => {},
  setStatus = () => {},
  disable = "",
  name = "",
  type = "relation",
  open,
  mode = "create",
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const navigate = useNavigate();
  const { relations } = useSelector((state) => state.userSummary);
  const [uniqueRelation, setUniqueRelation] = useState(false);
  const [relationExist, setRelationExist] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState("choose");
  const [selection, setSelection] = useState();
  const createNewRelations = createNewLifografConfig(mode);
  const description = createRelationDescription(
    mode === "create"
      ? t("display.text.text.your").toLowerCase()
      : t("display.text.text.add").toLowerCase()
  );

  //-----------------------Declare Functions Here-----------------------------//
  const handleApi = async () => {
    setLoading(true);
    let response = await getDirectRelation(relatedTo?.lg_id);
    setLoading(false);
    if (!response.error) {
      let isRelationExist = response.directRelationList.filter(
        (item) => item.relation === relationship
      );
      if (isRelationExist.length > 0) {
        if (relationship === "Father" || relationship === "Mother") {
          setCurrentPage("uniqueRelation");
          setUniqueRelation(isRelationExist);
          setStatus("alreadyExist");
        } else {
          setCurrentPage("relationExist");
          setRelationExist(isRelationExist);
          setStatus("areYouSure");
        }
      } else {
        close("next");
      }
    }
  };
  const handleSelection = (item) => {
    setSelection(item);
  };
  const handleSelectionNext = () => {
    if (selection === "others") setCurrentPage("noRelationPossible");
    else if (selection === "nieceNephew") setCurrentPage("nieceNephew");
    else {
      setCurrentPage("main");
    }
  };
  const handleNext = async () => {
    let relation = relations.filter((item) => item.userId === relatedTo?.lg_id);
    if (relation.length > 0) {
      if (
        (relation[0].rel_chain !== null &&
          relation[0].rel_chain?.includes("P")) ||
        relation[0].relation_source.toLowerCase().startsWith("step")
      ) {
        setCurrentPage("noRelationPossible");
        setStatus("invite");
      } else if (
        relation[0].relation == "Mother" ||
        relation[0].relation == "Father" ||
        relation[0].relation.toLowerCase().includes("parent")
      ) {
        if (relationship === "Son" || relationship === "Daughter") {
          setCurrentPage("noDesendantPossible");
          setStatus("invite");
        } else {
          handleApi();
        }
        /*Allow 'Brother', 'Sister', 'Father', 'Mother', 'Wife','Husband'
			
			Show this message if they choose "Son" or "Daughter" - "If you are trying to create the Lifograf of an Uncle/Aunt or GrandUncle/GrandAunt, please create them as a Sibling of an Ancestor. For example, to create the Lifograf of your own Uncle, create it as a Brother of your Father, instead of creating it as a Son of one of your GrandParents.
			
			To avoid incorrect Lifografs / unauthorized ownership, creating Lifografs of other step / distant relatives is not allowed.
			
			Instead, please use the below links to invite them/their family members to create their own Lifografs & then connect with them"*/
      } else if (
        relation[0].relation == "Son" ||
        relation[0].relation == "Daughter" ||
        relation[0].relation == "Self"
      ) {
        handleApi();
      } else if (relation[0].rel_chain.includes("S")) {
        if (relationship === "Husband" || relationship === "Wife") {
          handleApi();
        } else {
          setCurrentPage("onlySpousePossible");
          setStatus("invite");
        }
        /*Allow only Spouses.
			Main Ques - " Only direct or first level relatives can be created""

			Sub Text - "You can create Lifografs of your ancestors, children and their respective spouses. You can also create Lifografs of your siblings & siblings of your ancestors.

			To avoid incorrect Lifografs / unauthorized ownership, creating Lifografs of other indirect / distant relatives is not allowed. Instead, please use the below links to invite them or their family members to create their own Lifograf & then connect with them." */
      } else {
        setCurrentPage("noRelationPossible");
        setStatus("invite");
      }
    } else {
      setCurrentPage("notARelation");
      setStatus("oopse");
    }
  };
  const handleClose = () => {
    setCurrentPage("choose");
    setSelection();
    close();
  };
  return (
    <div>
      {currentPage === "choose" && (
        <CustomDialogSwipeUp
          open={open}
          close={handleClose}
          title={t("display.text.text.chooseRelationship")}
          iconPlacement="right"
        >
          <Stack spacing={2}>
            <Grid container>
              {Object.keys(createNewRelations).map((item, idx) => (
                <Grid
                  item
                  xs={4}
                  md={3}
                  key={idx}
                  sx={{
                    border: selection === item ? "3px solid #999999" : "unset",
                    filter:
                      selection === item
                        ? "drop-shadow(-4px 4px 4px rgba(0, 0, 0, 0.25))"
                        : "unset",
                    borderRadius: 1,
                    "&:hover": { backgroundColor: "rgba(140, 103, 158, 0.04)" },
                  }}
                >
                  <Button
                    startIcon={
                      <img style={{ height: "50px", width: "50px" }} src={""} />
                    }
                    sx={{
                      flexDirection: "column",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                    onClick={() => handleSelection(item)}
                    disableRipple={true}
                  >
                    <Typography
                      sx={{
                        display: "block",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: { xs: "64px", md: "128px" },
                        whiteSpace: "wrap",
                        color: "#333333",
                      }}
                    >
                      {t(`display.text.text.${item}`)}
                    </Typography>
                  </Button>
                </Grid>
              ))}
            </Grid>
            {selection && (
              <Card
                sx={{
                  width: "100%",
                  p: 1,
                  backgroundColor: "background.main",
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    flex: 1,
                    fontSize: "14px",
                  }}
                  paragraph
                >
                  {t(`display.text.text.desc${selection}`)}
                </Typography>
              </Card>
            )}
            <Divider></Divider>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <LoadingButton
                  sx={{ width: "30%" }}
                  variant="contained"
                  onClick={handleSelectionNext}
                  disabled={!selection}
                >
                  {t(`display.text.button.next`)}
                </LoadingButton>
              </Grid>
            </Grid>
          </Stack>
        </CustomDialogSwipeUp>
      )}
      {currentPage === "main" && (
        <CustomDialogSwipeUp
          open={open}
          close={handleClose}
          title={t("display.text.text.relationshipPopupHeader")}
          iconPlacement="right"
        >
          <Stack spacing={2}>
            <Card
              sx={{
                width: "100%",
                p: 1,
                backgroundColor: "background.main",
                display: "flex",
                borderLeft: "7px",
                borderLeftStyle: "solid",
                borderLeftColor: "info.main",
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 0,
              }}
            >
              {description[selection]}
            </Card>
            {name && (
              <Typography
                sx={{ display: "flex", justifyContent: "center" }}
                variant="h6"
              >
                {t("display.text.text.is", { param1: name })}
              </Typography>
            )}
            <Box
              sx={{
                mt: 3,
                display: { xs: "block", sm: "flex" },
                justifyContent: "space-between",
              }}
            >
              <TextField
                select
                label={t("display.text.inputField.relation")}
                value={relationship}
                disabled={disable === "relationship"}
                required
                onChange={(e) => setRelationship(e.target.value)}
                onKeyDown={(e) =>
                  e.key === "Enter" ? setRelationship(e.target.value) : null
                }
                variant="outlined"
                sx={{
                  width: { xs: "100%", sm: "45%" },
                  "&.MuiTextField-root .MuiInputBase-root": { height: "75px" },
                  "&.MuiTextField-root .MuiInputLabel-root": { top: "10px" },
                  "&.MuiTextField-root .MuiFormLabel-filled": {
                    top: "0px !important",
                  },
                  "&.MuiTextField-root .Mui-focused": { top: "0px" },
                }}
              >
                {createNewRelations[selection]
                  ?.relationshipList()
                  ?.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </TextField>
              <Typography
                sx={{ mt: 2, mb: 3, display: "flex", justifyContent: "center" }}
                variant="h6"
              >
                {t("display.text.text.of")}
              </Typography>
              <TextField
                select
                label={t("display.text.inputField.relatedTo")}
                value={relatedTo}
                required
                onChange={(e) => setRelatedTo(e.target.value)}
                onKeyDown={(e) =>
                  e.key === "Enter" ? setRelatedTo(e.target.value) : null
                }
                disabled={!relationship}
                variant="outlined"
                sx={{
                  width: { xs: "100%", sm: "45%" },
                  height: "75px",
                  "&.MuiTextField-root .MuiInputBase-root": { height: "75px" },
                  "&.MuiTextField-root .MuiInputLabel-root": { top: "10px" },
                  "&.MuiTextField-root .MuiFormLabel-filled": {
                    top: "0px !important",
                  },
                  "&.MuiTextField-root .Mui-focused": { top: "0px" },
                }}
              >
                {createNewRelations[selection]
                  ?.relatedToList()
                  ?.filter((item) =>
                    relationship === "Husband" || relationship === "Wife"
                      ? item.gender.toLowerCase() !==
                        chooseGenderFromValue(relationship)
                      : item
                  )
                  .map((option, index) => (
                    <MenuItem
                      sx={{ maxheight: "240px", maxWidth: "240px" }}
                      key={index}
                      value={option}
                    >
                      <Box
                        sx={{
                          display: "inline-flex",
                          alignItems: "center",
                          width: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textWrap: "nowrap",
                        }}
                      >
                        <CustomAvatar sx={{ mr: 1 }} src={option.readURL} />
                        <Typography
                          noWrap
                          sx={{
                            width: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textWrap: "nowrap",
                          }}
                        >
                          {option.fname + " " + option.lname}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
              </TextField>
              {/* </Box> */}
            </Box>
            {(relationship === "Brother" || relationship === "Sister") && (
              <Box sx={{ my: 2 }}>
                <GrannyTip message={t(`display.text.granny.siblings`)} />
              </Box>
            )}
            <Box>
              <GrannyTip message={t(`display.text.granny.choose${type}`)} />
            </Box>
            <Divider></Divider>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <LoadingButton
                  disabled={
                    relatedTo === null
                      ? true
                      : relationship === null
                      ? true
                      : false
                  }
                  sx={{ width: "30%" }}
                  variant="contained"
                  onClick={() => handleNext()}
                  loading={loading}
                >
                  {t(`display.text.button.add${type}`)}
                </LoadingButton>
              </Grid>
            </Grid>
          </Stack>
        </CustomDialogSwipeUp>
      )}
      {currentPage === "noRelationPossible" && (
        <DeleteEntryPromt
          buttonType="single"
          open={open}
          singleButtonOnClick={handleClose}
          close={handleClose}
          type="info"
          title={t("display.text.button.invite")}
          message={
            <Box>
              <Typography
                variant="body1"
                sx={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
              >
                {t("display.text.text.cannotCreateRelationInvitePromt")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  mt: 1,
                }}
              >
                <InvitePeople />
              </Box>
            </Box>
          }
        />
      )}
      {currentPage === "noDesendantPossible" && (
        <DeleteEntryPromt
          buttonType="single"
          open={open}
          singleButtonOnClick={handleClose}
          close={handleClose}
          type="info"
          title={t("display.text.button.invite")}
          message={
            <Box>
              <Typography
                variant="body1"
                sx={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
              >
                {t("display.text.text.noDesendantPossible")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  mt: 1,
                }}
              >
                <InvitePeople />
              </Box>
            </Box>
          }
        />
      )}
      {currentPage === "onlySpousePossible" && (
        <DeleteEntryPromt
          buttonType="single"
          open={open}
          singleButtonOnClick={handleClose}
          close={handleClose}
          type="info"
          title={t("display.text.button.invite")}
          message={
            <Box>
              <Typography
                variant="body1"
                sx={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
              >
                {t("display.text.text.onlySpousePossible")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  mt: 1,
                }}
              >
                <InvitePeople />
              </Box>
            </Box>
          }
        />
      )}
      {currentPage === "uniqueRelation" && (
        <DeleteEntryPromt
          buttonType="single"
          open={open}
          singleButtonOnClick={handleClose}
          close={handleClose}
          type="info"
          title={t("display.text.text.alreadyThere")}
          message={
            <Box>
              {" "}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CustomAvatar src={relatedTo?.readURL} sx={{ mr: 1 }} />
                <Typography>
                  {t("display.text.text.alreadyHasLifograf", {
                    param1: `${relatedTo?.fname} ${relatedTo?.lname}`,
                    param2: `${
                      relatedTo?.gender === "Male"
                        ? t("display.text.text.his")
                        : t("display.text.text.her")
                    }`,
                    param3: relationship,
                  })}{" "}
                  {t("display.text.text.youCanVisitLifografByClicking", {
                    param1: `${relatedTo?.fname} ${relatedTo?.lname}`,
                    param2: relationship,
                  })}
                </Typography>
              </Box>
              <Typography sx={{ mt: 2 }} align="center"></Typography>
              <center>
                <Button
                  color={"black"}
                  variant="text"
                  sx={{ mt: 2, justifyContent: "flex-start" }}
                  startIcon={
                    <CustomAvatar
                      sizes="small"
                      src={uniqueRelation[0].readURL}
                    />
                  }
                  onClick={() => {
                    navigate(
                      `/profile/${uniqueRelation[0].link.split("/")[4]}/about`
                    );
                    close();
                  }}
                >
                  {` ${uniqueRelation[0].name}`}
                </Button>
              </center>
            </Box>
          }
        />
      )}
      {currentPage === "relationExist" && (
        <DeleteEntryPromt
          open={open}
          onCancel={handleClose}
          onDelete={() => close("next")}
          // close={handleClose}
          type="info"
          title={t("display.text.text.alreadyThere")}
          message={
            <Box>
              <Box
                sx={{
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CustomAvatar src={relatedTo?.readURL} sx={{ mr: 1 }} />
                <Typography>
                  {t("display.text.text.alreadyhas", {
                    param1: `${relatedTo?.fname} ${relatedTo?.lname}`,
                    param2: relationExist.length,
                    param3: relationship,
                    param4:
                      relationExist.length > 1 ? t("display.text.text.s") : "",
                  })}
                </Typography>
              </Box>
              <Box sx={{ display: "inline-grid" }}>
                <ul>
                  {relationExist.map((item) => (
                    <li>
                      <Button
                        color={"black"}
                        variant="text"
                        sx={{ justifyContent: "flex-start" }}
                        startIcon={
                          <CustomAvatar sizes="small" src={item.readURL} />
                        }
                        onClick={() => {
                          navigate(`/profile/${item.link.split("/")[4]}/about`);
                          close();
                        }}
                      >
                        {` ${item.name}`}
                      </Button>
                    </li>
                  ))}
                </ul>
              </Box>
              <Typography variant="body1">
                {t("display.text.text.notCreatingDuplicate")}
              </Typography>
            </Box>
          }
        />
      )}
      {currentPage === "notARelation" && (
        <DeleteEntryPromt
          buttonType="single"
          open={open}
          singleButtonOnClick={handleClose}
          close={handleClose}
          type="info"
          title={t("display.text.button.invite")}
          message={t("display.text.granny.notARelation")}
        />
      )}
      {currentPage === "nieceNephew" && (
        <DeleteEntryPromt
          buttonType="single"
          open={open}
          singleButtonOnClick={handleClose}
          close={handleClose}
          type="info"
          title={t(`display.text.text.${selection}`)}
          message={description[selection]}
        />
      )}
    </div>
  );
};
