import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import family from "../../assets/Img/family.png";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { t } from "i18next";
import Fade from "@mui/material/Fade";
import { useSelector } from "react-redux";

const LoadingScreen = () => {
  //-----------------------Declare Hooks Here-----------------------------//
  const { usrName } = useSelector((state) => state.authentication);
  const [progress, setProgress] = useState(17);
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 100);

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Fade in={true} timeout={1500}>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white.main",
        }}
      >
        <Stack spacing={2}>
          <img
            style={{ height: "auto", maxWidth: "100%" }}
            src={family}
            alt="family"
          />
          <Typography align="center" variant="h5">
            {t("display.text.text.welcomeBack", {
              param1: usrName ? usrName.split(" ")[0] : usrName,
            })}
          </Typography>
          <Typography align="center" variant="body2">
            {t("display.text.text.welcomeBackNotes")}
          </Typography>
          <Box sx={{ px: 8 }}>
            <LinearProgress
              sx={{ backgroundColor: "white", borderRadius: 8 }}
              variant="determinate"
              value={progress}
              color="error"
            />
          </Box>
        </Stack>
      </Box>
    </Fade>
  );
};
export default LoadingScreen;
