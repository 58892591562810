import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import granny from "../assets/Img/Granny.png";
import CloseIcon from "@mui/icons-material/Close";

export const GrannyTip = ({ message, button = false, sx = {} }) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const [open, setOpen] = useState(message ? true : false);
  return (
    <>
      {open && (
        <Fade in={true} easing={{ enter: "1000", exit: "1000" }} timeout={2000}>
          <Box sx={{ ...sx }}>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
                mb: "-20px",
                mr: "5px",
                zIndex: 2,
              }}
            >
              <img style={{ height: "65px", width: "65px" }} src={granny} />
            </Box>
            <Card
              sx={{
                backgroundColor: "secondary.main",
                p: 0.8,
                display: "flex",
                position: "relative",
              }}
            >
              <Typography
                variant="body1"
                sx={{ color: "grannyTip.main", flex: 1 }}
                component={"div"}
              >
                {message}
              </Typography>
              <Box
                sx={{
                  position: "absolute",
                  p: 0,
                  height: "14px",
                  width: "14px",
                  right: "10px",
                  top: "-14px",
                  borderRadius: "50%",
                }}
              >
                {button && (
                  <Fade in={true} timeout={2000}>
                    <Button
                      sx={{
                        p: 0,
                        height: "14px",
                        width: "14px",
                        borderRadius: "50%",
                        minWidth: "0px",
                        minHeight: "0px",
                        zIndex: 10,
                      }}
                      variant="contained"
                      color="secondary"
                      onClick={() => setOpen(false)}
                    >
                      <CloseIcon
                        sx={{ fontSize: "14px", color: "white.main" }}
                      />
                    </Button>
                  </Fade>
                )}
              </Box>
            </Card>
          </Box>
        </Fade>
      )}
    </>
  );
};
