import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import { findSelf } from "../../utils/functions";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import "swiper/css";
import { introCardImage } from "../../utils/ListItems";

const IntroCard = ({ value }) => {
  return (
    <Box sx={{ p: 1 }}>
      {value === "nudgeAddRelations" && (
        <Card
          sx={{
            width: "100%",
            boxShadow: "0px 4px 4px 0px #00000040",
          }}
        >
          <img
            src={introCardImage[value]}
            style={{ width: "100%", maxHeight: "250px" }}
          />
          <Stack sx={{ p: 1 }}>
            <Typography
              sx={{ fontSize: "16px", fontWeight: 600, color: "#333333" }}
            >
              {" "}
              {t("display.text.text.introCardHomeFeedH1")}
            </Typography>
            <Typography sx={{ fontSize: "16px", color: "#666666" }}>
              {t("display.text.text.introCardHomeFeedD1")}
            </Typography>
            <Typography sx={{ fontSize: "16px", color: "#666666" }}>
              {t("display.text.text.introCardHomeFeedD2")}
            </Typography>
          </Stack>
        </Card>
      )}
      {value === "nudgeNewPost" && (
        <Card
          sx={{
            width: "100%",
            boxShadow: "0px 4px 4px 0px #00000040",
          }}
        >
          <img
            src={introCardImage[value]}
            style={{ width: "100%", maxHeight: "250px" }}
          />
          <Stack sx={{ p: 1 }}>
            <Typography
              sx={{ fontSize: "16px", fontWeight: 600, color: "#333333" }}
            >
              {" "}
              {t("display.text.text.introCardHomeFeedH1")}
            </Typography>
            <Typography sx={{ fontSize: "16px", color: "#666666" }}>
              {t("display.text.text.introCardHomeFeedD1")}
            </Typography>
            <Typography sx={{ fontSize: "16px", color: "#666666" }}>
              {t("display.text.text.introCardHomeFeedD2")}
            </Typography>
          </Stack>
        </Card>
      )}
      {value === "nudgeProfileDetails" && (
        <Card
          sx={{
            width: "100%",
            boxShadow: "0px 4px 4px 0px #00000040",
          }}
        >
          <img
            src={introCardImage[value]}
            style={{ width: "100%", maxHeight: "250px" }}
          />
          <Stack sx={{ p: 1 }}>
            <Typography
              sx={{ fontSize: "16px", fontWeight: 600, color: "#333333" }}
            >
              {" "}
              {t("display.text.text.introCardHomeFeedH1")}
            </Typography>
            <Typography sx={{ fontSize: "16px", color: "#666666" }}>
              {t("display.text.text.introCardHomeFeedD1")}
            </Typography>
            <Typography sx={{ fontSize: "16px", color: "#666666" }}>
              {t("display.text.text.introCardHomeFeedD2")}
            </Typography>
          </Stack>
        </Card>
      )}
    </Box>
  );
};

export const IntroCardHomeFeed = () => {
  const self = findSelf();
  const { nudgePost } = useSelector((state) => state.notification);
  const showCard = Object.values(nudgePost).includes(1);

  return (
    <Box sx={{}}>
      {showCard && (
        <Card sx={{ width: "100%", p: 1 }}>
          <Stack spacing={1}>
            <Typography
              sx={{ fontSize: "16px", fontWeight: 600, color: "#333333" }}
            >{`Hi ${self?.fname} !`}</Typography>
            <Typography sx={{ fontSize: "16px", color: "#666666" }}>
              {t("display.text.text.introCardHomeFeedP1")}
            </Typography>
            <Box sx={{ position: "relative" }}>
              <Box className="swiper-button image-swiper-button-next">
                <NavigateNextIcon />
              </Box>
              <Box className="swiper-button image-swiper-button-prev">
                <NavigateBeforeIcon />
              </Box>
              <Swiper
                navigation={{
                  nextEl: ".image-swiper-button-next",
                  prevEl: ".image-swiper-button-prev",
                  disabledClass: "swiper-button-disabled",
                }}
                modules={[Navigation]}
                className="mySwiper"
                slidesPerView={1}
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
              >
                {Object.keys(nudgePost)
                  .filter((item) => nudgePost[item] === 1)
                  .map((item) => (
                    <SwiperSlide>
                      <IntroCard value={item} />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </Box>
          </Stack>
        </Card>
      )}
    </Box>
  );
};
