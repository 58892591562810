import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentPage, updateMode } from "../../redux/slice/createLg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
const CreateNewLifografButton = () => {
  //-----------------------Declare Hooks Here-----------------------------//
  const dispatch = useDispatch();
  const { userStatus } = useSelector((state) => state.config);

  return (
    <Button
      variant="contained"
      startIcon={<AccountCircleIcon />}
      onClick={() => {
        dispatch(updateMode("new"));
        dispatch(updateCurrentPage("start"));
      }}
      disabled={userStatus === "inactive"}
    >
      <Typography>{t("display.text.text.createNewLifograf")}</Typography>
    </Button>
  );
};
export default CreateNewLifografButton;
