export const text = {
  button: {
    TermsofService: "Terms of Service.",
    signup1: "Agree & Sign Up",
    signup: "Sign Up",
    login: "Login",
    logout: "Logout",
    loginHere: "Login here",
    home: "Home",
    search: "Search",
    explore: "Family Tree",
    notification: "Notification",
    relationRequest: "Relation Request",
    addLifeEvent: "Add Life Event",
    save: "Save",
    cancel: "Cancel",
    about: "About",
    terms: "Terms",
    lifeEvents_one: "Life Event",
    lifeEvents: "Life Events",
    frozenMoments: "Cherished Moments",
    frozenMoments_one: "Cherished Moment",
    achievements: "Achievements",
    achievements_one: "Achievement",
    passDowns: "Pass Downs",
    passDowns_one: "Pass Down",
    loveAndWishes: "Love & Wishes",
    loveAndWishes_one: "Love & Wishes",
    anecdotes: "Anecdotes",
    anecdotes_one: "Anecdote",
    announcements: "Announcements",
    announcements_one: "Announcement",
    settings: "Settings",
    skip: "Skip",
    update: "Update",
    // addLifeEvent: "Add Life Event",
    addMoments: "Add Cherished Moments",
    addAchievements: "Add Achievements",
    add: "Add",
    addWisdom: "Share your wisdom",
    addAnecdotes: "Share an anecdote",
    addAnnouncement: "Make an announcement",
    shareWisdom: "Share Wisdom",
    shareAnecdotes: "Share Anecdote",
    shareAnnouncement: "Announce",
    wish: "Wishes",
    wish_one: "Wish",
    shareWish: "Send Wishes",
    addRelation: "Add Relation",
    requestSent: "Request Sent",
    createYourLg: "Start by creating your Lifograf",
    changePassword: "Change Password",
    next: "Next",
    react: "React",
    comment: "Add a comment ...",
    comments: "Comments",
    feed: "What do you want to share ?",
    addEvent: "Choose event",
    randomFeed: "Random Feed",
    yes: "Yes",
    no: "No",
    yesCreateNewLifograf: "Yes, Create new Lifograf",
    yesSendRequest: "Yes, Send Relation Request",
    yesSendrequest: "Yes, Send Relation Request",
    yesSendrelation: "Yes, Create new Lifograf",
    yesSendspouse: "Add Spouse",
    addspouse: "Add Spouse",
    addrequest: "Add Relation",
    addrelation: "Next",
    startLifograf: "Start My {{param1}}’s Lifograf",
    createLovedOnes: "Create loved ones",
    accept: "Accept",
    reject: "Reject",
    member: "Member",
    forgetPassword: "Forgot password ?",
    delete: "Delete",
    create: "Create",
    edit: "Edit",
    profileReaction: "Profile Reaction",
    addSpouse: "Add Spouse",
    addcolloborators: "Add Collaborator",
    addowners: "Add Owner",
    addblocked: "Block",
    preview: "Preview",
    invite: "Invite",
    sendwish: "Send Wish",
    activateLifograf: "Yes, it's my family",
    rejectLifograf: "No, not my family",
    buyInk: "Buy Ink",
    reset: "Reset",
    sendLinkAgain: "Send link again",
    randomThoughts: "Random thoughts",
    alert: "Alerts",
    proceed: "Proceed",
    ok: "Ok",
    info: "Info",
    apply: "Apply",
    upi: "Pay  with UPI",
    card: "Pay with card",
    subscribtion: "Subscription",
  },
  error: {
    fieldIsRequired: "{{param1}} is required",

    userNameValidationErr: "Only 20 characters are allowed",
    minimumCharacterError:
      "The Lifograf Handle needs to be atleast 4 chars long.",
    passwordValidationErr:
      "Password must has at least 8 characters that include at least 1 lowercase character, 1 uppercase characters, 1 number, and 1 special character in (!@#$%^&*)",
    passwordMissMatchErr:
      "Your password does not match with the password that you have entered",
    emailValidationErr: "Enter a proper email",
    enterPasswordFirstErr: "Enter Password First",
    apiFailed: "API call failed",
    somethingWentWrong: "Somethig Went Wrong",
    properpassword: "Enter a valid password",
    autoselectDropdownError:
      "Please select any one of the option from the drop down",
    chipValidationError: "Please select the options from the dropdown",
    lgUrl:
      "This Lifograf Handle Id is already taken, Please enter an unique Lifograf Handle Id",
    maxChipEntryError: "Only entries are allowed",
    maxCharError: "Only characters are allowed",
    noMoreChar: "An entry cannot be greater than chars",
    pipeOperatorError: "use of | is not allowed",
    maxCharacters: "Only {{param1}} characters were allowed",
    dateError: "Date cannot be in future",
    invalidYoutubeLink: "Only Youtube Links are allowed",
    invalidUrl: "Enter a properly formated URL",
    fileTooBig: "Please enter a file less than {{param1}}",
    incorrectFormat: "Please choose the correct file format",
    formatNotAllowed: "This file format is not supported",
    uploadFile: "Please upload a file",
    url_uniq: "This id is available",
    url_duplicate: "Id is not available. Please choose another Id",
    lg_created:
      "Lifograf Created Successfully ! Please update the other sections to complete your Lifograf",
    updated: "Updated Successfully",
    le_added: "Life Event Added !",
    le_updated: "Life Event Updated !",
    mem_added: "Frozen Moment Added !",
    mem_updated: "Frozen Moment Updated !",
    ach_added: "Achievement Added !",
    ach_updated: "Achievement Updated !",
    wish_added: "Wish Added !",
    wish_reaction_updated: "Reaction to Wish Added !",
    wisdom_added: "Wisdom Added !",
    wisdom_updated: "Wisdom Updated !",
    anc_added: "Announcement Added !",
    anc_updated: "Announcement Updated !",
    impress_added: "Impression Added !",
    impress_updated: "Impression Updated !",
    comment_added: "Comment Added !",
    comment_updated: "Comment Updated !",
    reaction_added: "Reaction Added !",
    reaction_removed: "Reaction Updated !",
    deleted: "Deleted Successfully !",
    Collab_added: "Collaborator Added",
    Collab_removed: "Collaborator Removed",
    relation_requested:
      "Your request has been sent & is awaiting confirmation. You can check status in your 'Relation Requests' tab",
    relation_req_decided: "Successfully Updated !",
    Verify_email:
      " Verification Link has been sent to your email id. Please check your email. If you don’t find it in your Inbox, please check your Spam folder.",
    Email_verfied: "Email successfully verified. You can now login",
    Logged_in: "",
    Reset_pwd_link: "Reset Link sent to your email",
    pwd_reset: " Password reset successfully",
    already_registered:
      "This email is already registered. Please login. If you have forgotten your Password, then use the 'Forgot Password' link in the Login Page",
    lgUrlType: "Only Alphabets, Numbers or Underscore is allowed in the Handle",
    invalid_cred:
      "Invalid credentials. Please check if you are using the correct email id & password",
    verify_pending:
      "Your email verification is pending. Please check your Inbox & please verify your email id before logging in.",
    parent_conflict:
      "These 2 people have different parents and hence cannot be siblings.",
    parent_exists: "The Lifograf for this person's parent already exists.",
    incomplete_request:
      "Request incomplete. Please validate your request & send a complete request.",
    corrupt_cred: "Invalid credentials. Please login Again!",
    lg_min_length: "The Lifograf Handle needs to be atleast 4 chars long.",
    email_reqr: "Email Id is required",
    Email_invalid: "Invalid Email format",
    url_invalid:
      "Only Alphabets, Numbers or Underscore is allowed in the Handle",
    incorrect_req:
      "Request incorrect. Please validate your request & send a complete request.",
    lg_exists: "This person already has a Lifograf",
    unauthorized_update:
      "You do not have rights to update or delete this entry",
    Lg_email_chng:
      "You can change your Lifograf's email id only by changing your login email id in your User Settings",
    lg_email_duplicate:
      "This is a Lifograf of another user. Only that user's email id is valid for this Lifograf",
    incorrect_img_format: "Only PNG or JPG file formats are accepted.",
    incorrect_file_format:
      "Incorrect file format uploaded. Please choose a right file format.",
    incomplete_file_delete_request:
      "Request incomplete for File  Deletion. Please validate your request & send a complete request.",
    file_missing: "Please upload a file or add a link",
    link_or_file:
      "You cannot add a link & upload a file together. Please choose one of these.",
    no_auth_comment: "You are not authorized to delete this comment.",
    incorrect_request:
      "Request incorrect. Please validate your request & send a correct request.",
    invalid_gtoken: "Invalid Google Token. Please try again.",
    unauthorized_login:
      "You are not authorized to login yet. Please contact admin.",
    reg_email_reqrd:
      "A valid Email Id and Password are mandatory for registration",
    verify_email_prv_sent:
      "A verification link has already been sent to your email id multiple times. If it's not in your Inbox, please check your Spam folders. To avoid spamming you, we avoid sending multiple emails on the same day. If you still need to sign up again, please try after 1 day.",
    already_verified:
      "Thank you. You have already verified your email id. You can login now",
    verify_link_expired:
      "You seem to have registered long back, but had left your email unverified. Hence your registration has expired. Please sign up again!",
    Verify_link_incorrect: "Incorrect verification link. Please sign up again!",
    incomplete_login: "A valid Email Id and Password are mandatory for login",
    fake_login:
      "Please sign up before logging in OR please use your Gmail Id to login through Google",
    Not_registered: "Please signup & then login.",
    Reset_link_expired:
      "Reset link has expired. Please try to reset your password again!",
    reset_link_incorrect:
      "Invalid link. Please try to reset your password again!",
    pwd_weak_wrong:
      "Please ensure password matches requirements & confirmation password is identical",
    already_related:
      "These 2 persons are already related. No new relation can be created",
    wrong_lgurl: "No such Lifograf exists. Please check the URL",
    incomplete_view_request:
      "Request incomplete. Please validate your request & send a complete request.",
    cred_missing:
      "Credentials missing. Please send the request with credentials",
    session_expired: "Session Expired. Please login Again!",
    invalid_secession:
      "Invalid Login or Session Expired, So Please login Again!",
    curr_pwd_wrong: "Please enter the correct current password ",
    not_registered: "This email is not registered. Please sign up.",
    reset_pwd_link_sent: "Reset password link sent",
    verify_reset_email_prv_sent:
      "A password reset link has already been sent to your email id multiple times. If it's not in your Inbox, please check your Spam folders. To avoid spamming you, we avoid sending multiple emails on the same day. If you still need to reset your password again, please try after 1 day",
    reset_link_invalid:
      "Invalid link. Please try to reset your password again!",
  },
  emoji: {
    anguished: "Anguished",
    surprised: "Surprised",
    brokenHeart: "Broken heart",
    celebrate: "Celebrate",
    cry: "Cry",
    delighted: "Delighted",
    happy: "Happy",
    takeCare: "TakeCare",
    treasure2: "Treasure",
    nostalgia: "Nostalgia",
    goldenTimes: "Golden times",
    loveIt: "Love it",
    cuddlyCute: "CuddlyCute",
    timeless: "Timeless",
    claps: "Claps",
    award: "Award",
    highVoltage: "High voltage",
    winners: "Winners",
    onFire: "On fire",
    thumbsUp: "Thumbs up",
    perfect: "Perfect",
    rofl: "Rofl",
    sad: "Sad",
    speechless: "Speechless",
    touching: "Touching",
    hatsOff: "HatsOff",
    stars: "Stars",
    sage: "Sage",
    treasure: "Treasure",
    thankYou: "Thank you",
    hi5: "Hi5",
    praying: "Praying",
    goodLuck: "Good luck",
    wishSuccess: "Wish success",
    cool: "Cool",
    blessYou: "Bless you",
    party2: "Party",
    cheering: "Cheering",
    hugs: "Hugs",
    birdsOfFeather: "Birds Of Feather",
    realHero: "Real Hero",
    pillarOfStrength: "Pillar Of Strength",
    heartOfGold: "Heart Of Gold",
    gemOfAPerson: "Gem of a person",
    guidingLights: "Guiding lights",
    respect: "Respect",
    fireCracker: "Fire cracker",
    connected: "Connected",
    admiration: "Admiration",
    sunShine: "Sun shine",
    guardianAngel: "Guardian angel",
    seekBlessing: "Seek blessing",
  },
  granny: {
    selfLg:
      "A Lifograf is a personal biography for your family  & future generations to know you. You can  always edit it & add more details over time. You can also create / contribute to the Lifografs of your loved ones  later",
    newLg:
      " A Lifograf is a personal biography for a person’s family & future generations to know them. We can add more details over time & also change details as needed later. You are the Owner of Lifografs that you create, but other relatives are also added as Collaborators to allow them to contribute.",
    chooseRelation:
      "Note that a blood relation once established, just like in real life, cannot be deleted or changed. So please do not create test or dummy relations.",
    chooserelation:
      "Note that a blood relation once established, just like in real life, cannot be deleted or changed. So please do not create test or dummy relations.",
    emptyLifeEventP1:
      "Chronicle {{param1}}'s life events, milestones and embark on a remarkable voyage of {{param2}}, reflection, and gratitude.",
    emptyLifeEventSelfP1:
      "Chronicle {{param1}} life events, milestones and embark on a remarkable voyage of {{param2}}, reflection, and gratitude.",
    emptyMomentsP1:
      " Share {{param1}}'s memorable moments and cherish these invaluable fragments of your life's tapestry. Whether it's the smile of a loved one, the first steps of a child, or the echo of a heartfelt conversation, these moments are a gift to {{param1}} and those who will come after {{param1}}",
    emptyMomentsSelfP1:
      " Share {{param1}} memorable moments and cherish these invaluable fragments of your life's tapestry. Whether it's the smile of a loved one, the first steps of a child, or the echo of a heartfelt conversation, these moments are a gift to {{param1}} and those who will come after you",
    emptyWisdomP1:
      "Share the most important lessons and wisdom from {{param1}}'s life journey here. In doing so, you create a legacy of knowledge and empowerment, touching hearts and minds. So get started",
    emptyWisdomSelfP1:
      "Share the most important lessons and wisdom from {{param1}} life journey here. In doing so, you create a legacy of knowledge and empowerment, touching hearts and minds. So get started",
    emptyAchievementsP1:
      "Life is an incredible journey filled with countless accomplishments, big and small. Be it a photo of something handmade by you OR an audio snippet of a song in your voice OR an award / certificate  - add these for now & for posterity",
    emptyAnecdotesSelf:
      "This is a place for you where your loved ones can share anecdotes about you or even just their overall impression about you. Sit back & wait for your family to share events & stories about you that are forever green in their minds. Want to share an anecdote about another family member ? - just go to their Lifograf & add an Anecdote",
    emptyAnecdotesP1:
      "Do you have a memory or an anecdote or an impression to share about {{param1}} ? Share anecdotes & stories involving {{param1}} and show how those shared moments are evergreen for you",
    emptyAnecdotesP2:
      "These anecdotes reveal the intricate details that make your relationship with |First Name| unique. By sharing these anecdotes & stories, you're also giving them a gift—an acknowledgment of their impact and a testament to their significance. These anecdotes transform into bridges that bridge gaps and cultivate a sense of unity among us all.",
    emptyWishSelf:
      "This is a place where your family can share their love & wishes with you. Sit back & read through the love of your family",
    emptyWishP1:
      "Share your love with {{param1}} through a wish. Whether it’s a anniversary / festive greeting / a general wish, your words can make someone’s day. Go ahead & send a note of love & good wishes",
    emptyAnnouncementSelfp1:
      "Share updates, seek help & make announcements with your family here. You're not just sharing news or requesting help; you're weaving the fabric of togetherness.",
    addRelativesToAddWish:
      "You are not connected to any relative to Wish them, If you want to send a wish, then please Invite relatives or Create a loved one's Lifograf",
    addRelativesToAddAnecdotes:
      "You are not connected to any relative to add aecdotes to them, If you want to add aecdotes, then please Invite relatives or Create a loved one's Lifograf",
    notARelation:
      "You are not related to the person, you have to be related in order to create Lifograf for the person",
    chooserequest:
      "Note that a blood relation once established, just like in real life, cannot be deleted or changed. So please do not create test or dummy relations.",
    choosespouse:
      "This option is to add a secondary Spousal relationship to an existing relation. This will not be applicable for most families & is applicable only for families where marriage is / was practiced within cousins. Please use this option with discretion",
    siblings:
      "A person is your Brother / Sister only if you both share the same Mother and same Father.",
  },
  helperText: {
    lgUrl:
      "Choose a unique handle to help people search for this Lifograf more easily. This cannot be changed later.",
    shortDescription:
      "1 or 2 lines that serves as a Slogan OR Tagine. This will be highlighted at the top of the Lifograf. Some people also use a Quote that they live by or lines that describe their core values",
    introduction:
      "5 to 10 lines that provide a short introduction to the person’s life.",
    caste: "Hereditary classes of societies. Common in Asian countries",
    star: "A key aspect of Indian Astrology referring to Lunar asterisms based on Birth Date / Time",
    clan: "A name for people belonging to a common ancient ancestry. Common in countries like India, Scotland etc.",
    mortalRemainsLocation:
      "The specific location in which the mortal remains were buried, cremated etc. E.g. The name/location of a burial ground or the name of river in which mortal remains were spread",
    additionalDetails:
      "Any additional details you want to add to this section. It could be new information or more details / elaboration on the above information",
    Visibility: `Show or hide the update based on the family circle:
    |Immediate - Spouse, Children & all Descendants
    |Extended - Immediate + Parents, Grandparents etc. + Your Siblings’ families
    |1st Cousins - Extended + Your 1st Cousin families
    |All Cousins - All cousin families
    |Public - Anyone logged into Famscape
    |Note that your Spouse’s relatives are included in the respective categories `,
    birthPlace:
      "If your exact city is not found in the list, please choose the nearest city",
    primaryOccupation:
      "The main occupation through which livelihood was earned",
    secondaryOccupation: "Additional occupations or side hustles",
    chipMobile: "Add comma to complete an entry",
    chipDesktop: "Type & then hit enter",
    trending: "Topics being discussed by other families in Famscape",
    colloborators: `Collaborators have the ability to contribute to the different sections of a person’s Lifograf, but do not have other rights of an Owner. Collaborators have the ability to edit / delete entries created by themselves

You become a Collaborator of a person’s Lifograf if you are their descendant.
A person can remove collaborators for their own Lifograf only
Every user is automatically the collaborator for the Lifografs of their ancestors. Owners can add or remove any relative of a Lifograf as Collaborators.`,
    owners: `Owners have complete control over a Lifograf.  Owners have the ability to :
    1. Contribute to different sections of a Lifograf
    2. Update the About section of the Lifograf
    3. Make, accept or reject Relation Requests for the Lifograf
    4. Edit / Delete contributions by other collaborators
    
    Every user automatically owns their own Lifograf. A creator of a Lifograf is the first owner of a Lifograf. Owners can add other relatives as Owners. However, people unrelated to the Lifograf cannot be made owners.
    
    Note that a Owner can remove other Owners, but can never remove themselves.
    `,
    blocked: `Users blocked from a Lifograf cannot send Wishes or add Anecdotes for that Lifograf. 

Blocked users cannot contribute even comments & reactions to the Lifograf
    
Please avoid using Blocks. Users should be Blocked used only in extreme cases where a relative is repeatedly being unkind or abusive in the Lifograf.`,
  },
  inputField: {
    email: "Email",
    password: "Password",
    confirmPassword: "Confirm Password",
    userName: "User Name",
    firstName: "First Name",
    lastName: "Last Name",
    gender: "Gender",
    nickname: "Ancestral Family Name/Title",
    lifografHandle: "Lifograf Handle",
    alive: "Alive",
    shortDescription: "Tagline / Motto / Slogan",
    introduction: "Introduction",
    basicAddDetails: "Additional details",
    dob: "Date of Birth",
    birthTime: "Birth Time",
    dod: "Date of Death",
    birthCountry: "Birth Place",
    nationality: "Nationality",
    nativeLanguage: "Native Language",
    race: "Race",
    religion: "Religion",
    caste: "Class / Caste",
    clan: "Clan / Gothra",
    star: "Birth Star",
    lastRes: "Last Place of Residence",
    currentRes: "Current Place of Residence",
    EnterAcity: "Enter a city",
    CauseofDeath: "Cause of Death",
    MortalRemainsLocation: "Mortal Remains Location",
    AdditionalDetails: "Additional Details",
    Visiblity: "Visiblity",
    educationLevel: "Education Level",
    educationQualification: "Educational Qualification",
    educationArea: "Field of Education",
    schoolsAttended: "Schools Attended",
    collegesAttendes: "Colleges/Universities Attended",
    organizationWorkedIn: "Organizations worked in",
    bloodGroup: "Blood Group",
    physicalActivity: "Physical Activity",
    chronicIllness: "Chronic Illnesses",
    smoking: "Smoking",
    drinking: "Drinking",
    bestQuality: "Endearing Qualities",
    worstQuality: "Weaknesses",
    hobbies: "Hobbies",
    favouriteFood: "Favourite Food",
    favouriteSports: "Favourite Sports",
    favouriteMusic: "Favourite Music",
    favouriteBooks: "Favourite Books",
    OrganizationWorkedIn: "Organizations worked in",
    primaryOccupation: "Primary Occupation",
    secondaryOccupation: "Secondary Occupation",
    occupationalDetails: "Occupational Details",
    ancRegion: "Ancestral Region/Village",
    noOptionTextCities: "Type in atleast 5 chars",
    noCitiesFound: "No such city found...please try another nearby city",
    eventType: "Event Type",
    eventDate: "Event Date",
    title: "Title",
    description: "Description",
    uploadPic: "Upload Pic",
    changePic: "Change Pic",
    deletePic: "Delete Pic",
    date: "Date",
    fileType: "File Type",
    link: "Link",
    uploadImage: "Upload Image",
    uploadapplication: "Upload PDF",
    changeImage: "Change Image",
    uploadVideo: "Upload Video",
    changeVideo: "Change Video",
    uploadAudio: "Upload Audio",
    changeAudio: "Change Audio",
    Changeapplication: "Change PDF",
    announcementType: "Announcement Type",
    wisdomType: "Wisdom Type",
    wishType: "Wish Type",
    relation: "Relation",
    relatedTo: "Related To",
    change: "Change {{param1}}",
    upload: "Upload {{param1}}",
    delete: "Delete {{param1}}",
    add: "Add {{param1}}",
    audio: "audio",
    image: "image",
    video: "video",
    application: "PDF",
    photo: "Photo",
    coverPhoto: "Cover Photo",
    weddingDate: "Wedding Date",
    divorceDate: "Divorced Date",
    update: "Update {{param1}}",
    currentPassword: "Current Password",
    newPassword: "New Password",
    confirmPassword: "Confirm Pasword",
    attach: "Attach",
    wishType: "Wish Occasion ",
    "Video - Large": "Video",
  },
  text: {
    termsAndCondition:
      "By accessing or using any part of the site, you agree to be bound by these ",
    // "By clicking Agree & Sign Up, you agree to the Lifograf User Agreement, Privacy Policy, and Cookie Policy.",
    signupHeader: "Stay updated with your family",
    signupFooter: "Have your own Lifograf ? ",
    signup: "Sign Up",
    login: "Login",
    loginHeader: "Connect with your family",
    loginFooter: "Don't Have your own Lifograf ? ",
    ownedLg: "Owned by you",
    sharedWithYou: "LG Shared with you",
    lifograf: "Lifograf",
    createLgAboutTitle: "Create Lifograf Profile",
    createLgPersonalTitle: "Personal Details",
    createLgHealthTitle: "Health Details",
    createLgEducationTitle: "Education & Work Details",
    createLgPersonalityTitle: "Personality Details",
    createNewLifograf: "Create",
    edit: "Edit",
    relationshipPopupHeader: "Start the Lifograf of",
    editModeLgAboutTitle: "Introduction",
    editModeLgPersonalTitle: "Edit personal details",
    editModeLgHealthTitle: "Edit health details",
    editModeLgEducationTitle: "Edit education details",
    editModeLgOccupationTitle: "Edit occupation details",
    editModeLgPersonalityTitle: "Edit personality details",
    addLifeEvent: "Add Life Event for",
    noColloborations: "No Collaborations",
    colloborations: "Collaborations",
    reaction: "Reaction",
    reactions: "Reactions",
    noReactions: "No Reactions",
    comment: "Comment",
    comments: "Comments",
    noComments: "No Comments",
    deleteConfirmation: "Are you sure you want to delete this {{param1}}?",
    addMoments: "Add Cherished Moment for",
    addAchievements: "Add Achievements for",
    noFile: "No files uploaded",
    addWisdom: "Share your wisdom",
    addAnecdotes: "Add anecdotes for",
    addAnnouncement: "Make An Announcement",
    wishYou: "wish you",
    addWish: "Share your wish for",
    requestRecieved: "Request Received",
    requestSent: "Request Sent",
    noRelationRequest: "No relation request",
    requestedBy: "Requested by",
    on: "on",
    you: "You",
    areThe: "Are the",
    isThe: "Is the",
    of: "of",
    approvedBy: "Confirmed by",
    rejectedBy: "Rejected by",
    pending: "Pending",
    created: "created",
    updated: " updated ",
    commented: "commented :",
    reacted: "reacted :",
    wisdom: " a Wisdom ",
    lifeEvent: " a Life Event ",
    cherishedMoments: " a Cherished Moment ",
    anecdotes: " an Anecdotes ",
    announcement: " an  Announcement ",
    wish: " a Wish ",
    achievement: " an Achievement ",
    homeFeedEndMessage: "Yay! You have seen it all",
    to: "to",
    introHeading: "Welcome to FamScape !",
    introP1:
      "Families are the building blocks of a healthy society. Research shows that strong family relationships help individuals with better emotional regulation, create a reliable & trusted network, improve mental health, raise our social competence, and increase our resilience.",
    introP2:
      "However, in today's fast-paced & tech-dominated world, family connections often takes a backseat. We know very little about our grandparents and even lesser about our great grandparents. With limited opportunities to interact, extended family relations fade over time, removing one of the most important sources of identity, strength & inspiration for every individual.",

    introP3:
      "FamScape is an effort to help us know our family trees better, strengthen family connections, rediscover lost relations & document our life stories for future generations.",
    introP4:
      "Thanks for joining us on this journey to preserve & strengthen family bonds !!",
    emailVerificationH1: "Welcome to the family !!",
    emailVerificationP1: "A verification link has been sent to you email id -",
    emailVerificationP2: "Please click on the verification link & you are set",
    newLifograf: "New Lifograf",
    editLifograf: "Edit Lifograf",
    selfLifograf: "Create Your Own Lifograf",
    about: "About",
    personal: "Personal",
    education: "Education",
    lgAboutNoInfo: "No information added yet",
    introduction: "Introduction",
    occupation: "Occupation",
    health: "Health",
    personality: "Personality",
    relations: "My Family",
    colloboration: "My Lifografs",
    sortAscending: "Sort in ascending order",
    sortDesending: "Sort in desending order",
    noLifeEvent: "No Life Events are available for you to view ...",
    noFrozenMoments: "No Cherished Moments are available for you to view ...",
    noAchievement: "No Achievements are available for you to view ...",
    noAnnouncement: "No Announcement are available for you to view ...",
    noWish: "No Wish are available for you to view ... ",
    noAnecdotes: "No Anecdotes are available for you to view ... ",
    noWisdom: "No Pass Downs available for you to view ...",
    useApproxDate: "Use approx date, if unsure",
    enterAComment: "Enter a comment",
    added: " added ",
    received: " received ",
    from: " from ",
    by: "by ",
    le: " life Event ",
    anc: " announcement ",
    impress: " anecdotes ",
    showAllComments: "Show all comments",
    linkCopied: "Link Copied",
    whatsapp: "Whats App",
    facebook: "Facebook",
    copylink: "Copy Link",
    dear: "Dear",
    love: "With love,",
    wishFrom: "A wish from",
    reactedTo: " reacted to ",
    isYourRelative: "Is the biological {{param1}} of {{param2}}",
    isYourRelativeHomeFeed: "is {{param1}}'s biological {{param2}} ?",
    son: "son",
    daughter: "daughter",
    father: "father",
    mother: "mother",
    potentialRelative: "Are you related ?",
    cannotCreateRelationInvitePromt: `Only direct or first level relatives can be created
You can create Lifografs of your ancestors, children and their respective spouses. You can also create Lifografs of your siblings & siblings of your ancestors.
To avoid incorrect Lifografs / unauthorized ownership, creating Lifografs of other indirect / distant relatives is not allowed. Instead, please use the below links to invite them or their family members to create their own Lifograf & then connect with them.`,
    noDesendantPossible: `If you are trying to create the Lifograf of an Uncle/Aunt or GrandUncle/GrandAunt, please create them as a Sibling of an Ancestor. For example, to create the Lifograf of your own Uncle, create it as a Brother of your Father, instead of creating it as a Son of one of your GrandParents.
To avoid incorrect Lifografs / unauthorized ownership, creating Lifografs of other step / distant relatives is not allowed.
Instead, please use the below links to invite them/their family members to create their own Lifografs & then connect with them`,
    onlySpousePossible: `Only direct or first level relatives can be created
You can create Lifografs of your ancestors, children and their respective spouses. You can also create Lifografs of your siblings & siblings of your ancestors.
To avoid incorrect Lifografs / unauthorized ownership, creating Lifografs of other indirect / distant relatives is not allowed. Instead, please use the below links to invite them or their family members to create their own Lifograf & then connect with them.`,
    alreadyHasLifograf:
      "{{param1}} already has a Lifograf for {{param2}} {{param3}}",
    his: "his",
    her: "her",
    youCanVisitLifografByClicking:
      "You can visit {{param1}}'s  {{param2}} by clicking on the below Link",
    alreadyhas: "{{param1}} already has {{param2}} {{param3}}{{param4}}",
    s: "s",
    notCreatingDuplicate: "Are you sure you are not creating a duplicate ?",
    the: "the",
    grannySuggests: "Granny suggest you to",
    createYour: "Create Your {{param1}}'s Lifograf",
    addMother:
      "A mother is a cornerstone of a person's emotional foundation, instilling values, wisdom, and resilience. Start creating her Lifograf & eternalize your mother’s life story by which even future generations can know & remember her",
    addFather:
      "A father's love might sometimes be quieter, yet it resonates deeply, providing a grounding force and a steadfast anchor in life's tumultuous seas. It’s only appropriate for you to start your Father’s Lifograf, documenting his life’s triumphs & tribulations",
    addBrother:
      "Our family is the cornerstone of our lives, a sanctuary of love, support, and unwavering understanding. Go ahead create the Lifograf of your family members",
    addSister:
      "Our family is the cornerstone of our lives, a sanctuary of love, support, and unwavering understanding. Go ahead create the Lifograf of your family members",
    addSon:
      "Our family is the cornerstone of our lives, a sanctuary of love, support, and unwavering understanding. Go ahead create the Lifograf of your family members",
    addDaughter:
      "Our family is the cornerstone of our lives, a sanctuary of love, support, and unwavering understanding. Go ahead create the Lifograf of your family members",
    addHusband:
      "Our family is the cornerstone of our lives, a sanctuary of love, support, and unwavering understanding. Go ahead create the Lifograf of your family members",
    addWife:
      "Our family is the cornerstone of our lives, a sanctuary of love, support, and unwavering understanding. Go ahead create the Lifograf of your family members",
    createdBy: "by {{param1}} on {{param2}}",
    noFeed: "No feed to show",
    lgScore: "Lifograf  Score: {{param1}}%",
    engagementScore: "Your Lifograf engagement score : {{param1}}",
    your: "your",
    selfDiscovery: "self-discovery",
    discovery: "discovery",
    possibleRelativeList: "{{param1}} is",
    referral: "is directly related to you or your family members ?",
    or: "or",
    alreadyUser: "Already an Existing User ? ",
    alreadyNotUser: "Don't have an account yet ? Please ",
    welcomeBack: "Welcome back {{param1}}",
    welcomeBackNotes:
      "We are setting up your family please wait for a moment !!",
    troubleLogin: "Trouble logging in ",
    trending: "Trending",
    userStatus: "User status : {{param1}}",
    lgScore1: "Lifograf Score: {{param1}}",
    engagementScore1: "Engagement score : {{param1}}",
    lgScore2: "Lifograf Score",
    engScore: "Engagement Score",
    bonding: "Bonding",
    delete: "Delete ?",
    uploadingImage: "Uploading ...",
    new: "Create New",
    welcome: "Welcome !",
    famscape: "Famscape",
    newLifografOf: "{{param1}}'s {{param2}}",
    chooseRelation: "Choose relationship",
    spouse: "Spouses",
    spouse_one: "Spouse",
    nothingFound: "No results found please try searching in other categories",
    somethingToSearch: "Type LgUrl or type something to search the Lifograf",
    searchIn: "Search in",
    accountSettings: "Account settings",
    lifografSettings: "Lifograf settings",
    passwordSettings: "Change Password",
    manageLifograf: "Manage Lifograf",
    basicInfo: "Basic Info :",
    changePassword: "Change password",
    fname: "First name : {{param1}}",
    lname: "Last name : {{param1}}",
    lg_url: "Famscape URL : {{param1}}",
    gender: "Gender : {{param1}}",
    owner_type: "Owner type : {{param1}}",
    planDetails: "Plan details :",
    existing: "Existing : {{param1}}",
    allowed: "Allowed : {{param1}}",
    remaining: "Remaining : {{param1}}",
    active: "Active",
    inactive: "Inactive",
    colloborators: "Collaborators",
    colloborators_one: "Collaborator",
    owners: "Owners",
    owners_one: "Owner",
    blocked: "Blocked",
    add: "Add {{param1}}",
    remove: "Remove",
    confirmRemove: "Are you sure you want to remove {{param1}} ?",
    confirmUnblock: "Are you sure you want to unblock ?",
    preview: "Preview",
    premium: "Premium",
    startTyping: "What's on your mind, {{param1}}?",
    whatsonyourmind: "Want do you want to add today ?",
    visitProfile: "View Lifograf",
    notification: "Notification",
    receiveNotification:
      "Would you like to recieve notification from your family ?",
    activateLifografHeading: "activate your Lifograf",
    activateLifografBody:
      "your Lifograf was maintained by your relative since your are hereplease take control of your Lifograf but activating your Lifograf orstart your Lifograf from scratch",
    areYouSure: "Are you sure ?",
    rejectActivateLifografMessage:
      "If you believe that your email id has been added by mistake & these are not your relatives, then you can confirm. Once you confirm, you will be dissociated from all the relatives you currently see. You can login back again to create your Lifograf & then use it to connect with your real family",
    invitePeople: "Invite your relatives",
    noRequestSent: "No request sent",
    noRequestReceived: "No request received",
    confirmRelationRequestPromt:
      "Relationship once established cannot be changed are you sure about the relationship ?",
    endOfFeed: "... And those were the latest from your family",
    noFeed:
      "Latest updates from your family members will show up here once you are connected on Famscape",
    is: "{{param1}} is ",
    noEligible: "No eligible relatives found",
    tapToTurnCard: "Tap to turn the card",
    areYouSureRejectFamily: "Are you sure this is not your family ?",
    ActivateLifografMessage:
      "Once you confirm that this is your family and the relationships are correct, the relationships will be established and you cannot change it later. Please check out the family tree to ensure that this is really your family & your Lifograf. Ensure your email has not been added by someone incorrectly",
    confirmEmailPromt: `Are you sure the updated email id is correct ? 

You have updated the email id of this Lifograf. We will send a mail to this email id requesting confirmation. If confirmed, this email id will have complete control over this Lifograf.`,
    updatePromtHeading: "OOPS !!",
    updatePromtBody:
      "you ran out of ink to write your lifograf please buy more ink to continue writing",
    contactUs: "Contact Us",
    privacyPolicy: "Privacy policy",
    terms: "Terms and Condition",
    copyWrite: "Copy Writed",
    chooseSecondaryRelation: "Secondary Spousal Relation",
    selectOne: "What do you want to add ?",
    familyTree: "{{param1}}'s Family Tree",
    invite: "Send an Invite",
    oopse: "!! Oopse !!",
    alreadyExist: "Are you sure ?",
    alreadyThere: "Already exist",
    forgetPassword: "Forget password ?",
    forgetPasswordDescription:
      "Please enter your email Id you register with famscape. We will sent a link, click on the link to change the password",
    forgetPasswordDescription:
      "we have sent a reset password link to your email please click on the link to reset your password",
    resetPassword: "Reset password ?",
    immediate: "Immediate",
    extended: "Extended",
    firstCousins: "1st Cousins",
    distant: "Distant",
    noRelation: "No relations connected yet !",
    useCreateOrInvite:
      "Use 'Create' or 'Invite' button to start connecting with family !",
    approachingBirthday:
      "{{param1}}'s {{param2}} birthday is approaching on {{param3}}",
    descriptionle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    descriptionmem:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    descriptionanc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    descriptionach:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    descriptionwish:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    descriptionwisdom:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    descriptionimpress:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    descriptionrandom:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    parents: "Parents",
    grandParents: "Grand parents",
    siblings: "Siblings",
    stepSiblings: "Step siblings",
    uncleAunt: "Uncle / Aunt",
    cousin: "Cousin",
    spouse: "Spouse",
    children: "Children",
    grandChildren: "Grand children",
    inlaws: "In laws",
    nieceNephew: "Niece / Nephew",
    others: "Others",
    chooseRelationship: "Choose Relationship",
    descparents: "Parents",
    descgrandParents: "Grand parents",
    descsiblings: "Siblings",
    descstepSiblings: "Step siblings",
    descuncleAunt: "Uncle / Aunt",
    descspouse: "Spouse",
    descchildren: "Children",
    descgrandChildren: "grandChildren",
    desccousin: "Cousin",
    descinlaws: "In laws",
    descnieceNephew: "Niece / Nephew",
    descothers: "others",
    homeFeedPromtP1:
      "Famscape is a private, ad-free & safe place for you & your family to capture your life’s key moments, remember ancestors, strengthen family bonds, rediscover lost relations & build a legacy for generations",
    homeFeedPromtP2:
      "Write your Life Stories - one memory, one greeting, one life event at a time",
    homeFeedPromtP3:
      "Invite your siblings, cousins, build your family network & stay close, even if you are all physically far away",
    homeFeedPromtP4:
      "Rediscover the joy of having a large family to share, to joke, to support, to care & to love",
    introCardHomeFeedP1:
      "Continue bonding with your family by completing the below steps and increase your bonding score. Higher Bonding Scores will move you to higher Famscape Leagues and unlock newer features.",
    introCardHomeFeedH1: "Create your parents",
    introCardHomeFeedD1:
      "Click on the ‘Create’ button to create the Lifograf of your Parents. ",
    introCardHomeFeedD2:
      "You can invite any famiy member to Famscape after creating their Lifograf, by simply adding their email id in their Lifograf ",
    aniversary: "Almost {{param1}} year ago",
    exactAniversary: "On this day {{param1}} year ago",
    youHave: "{{param1}}'s subscription",
    inkBottle: "Ink Bottles",
    flinksLeft: "{{param1}} left of {{param2}}",
    subscribeToRelatives:
      "You can gift subscriptions to your family members too !",
    subscribeFor: "Subscribe for",
    addInkBottles: "Add ink bottles",
    couponPlaceholder: '"Enter coupon code"',
    paymentMethod: "Payment method :",
    notifications: "Notifications",
  },
  toolTip: {
    Owner: "You are the Owner of this Lifograf",
    Collab: "You are a Collaborator on this Lifograf",
    Self: "You",
  },
  lgAbout: {
    and: " and",
    He: "He",
    She: "She",
    Her: "Her",
    His: "His",
    he: "he",
    her: "her",
    she: "she",
    his: "his",
    was: "was",
    is: "is",
    wasBornIn: "{{first_name}} was born in {{birth_cntry}}. ",
    wasBornOn: "{{param1}} was born on {{dob}}. ",
    a: "{{param1}} {{param2}} a {{race}}. ",
    wasBorn: "{{param1}} was born ",
    underTheStar: "under the {{star}} star ",
    inTheCaste: "in the {{caste}} caste ",
    inTheClan: "in the {{clan}} clan. ",
    ancestralVillage:
      "{{param1}} family originally hails from the ancestral region/village {{param2}} of {{anc_region}}. ",
    familyTitle:
      "{{param1}} family’s generational honorary title is {{ancestral_title}}. ",
    nativeLanguage:
      "{{param1}} native language {{param2}} {{native_language}}. ",
    currentResidence: "{{param1}} currently resides at {{last_res}}. ",
    dead: "{{param1}} passed away ",
    dod: "on {{dod}} ",
    deathLocation: "in {{dth_loc}}. ",
    lastResidence: "{{param1}} last residence was {{last_res}}. ",
    mortalRemains: "{{param1}} remains are at {{mortal_remains_location}}",
    school: "{{first_name}} did {{param1}} schooling in {{schools}}. ",
    college: "{{param1}} alma mater included {{colleges}}. ",
    educationalArea:
      "{{param1}} completed education in the fields of {{edu_area}}. ",
    educationalQualification:
      "{{param1}} qualifications include {{edu_qual}}. ",
    mainOccupation: "{{param1}} primary occupation was {{main_occupation}}. ",
    secondaryOccupation:
      "Additionally {{param1}} also worked in {{sec_occupation}}. ",
    secondaryOccupationMod1: "{{param1}} worked in {{sec_occupation}}. ",
    workplaces: "Over {{param1}} career {{param2}} worked in {{workplaces}}. ",
    bloodGroup: "{{first_name}}'s blood group was {{blood_group}}. ",
    smokingNone: "{{param1}} never smoked. ",
    experimentSmoking:
      "{{param1}} experimented with smoking but did not become a smoker. ",
    smoker: "{{param1}} was a {{smoking}} smoker. ",
    drinkingNone: "{{param1}}  was a teetotaller. ",
    experimentDrinking:
      "{{param1}}  experimented with drinking but remained a teetotaller. ",
    drinking: "{{param1}} was a {{drinking}} drinker. ",
    illness: "{{param1}} suffered from {{chronic_illness}}. ",
    activity: "{{param1}} physical activity was {{physical_activity}}.",
    bestQual:
      "{{first_name}} was admired and loved for {{param1}} {{best_qual}}. ",
    worstQualMod1: "However {{param1}} was also sometimes {{worst_qual}}. ",
    worstQualMod2: "{{first_name}} was sometimes {{worst_qual}}. ",
    hobbies: "{{param1}} hobbies included {{hobbies}}. ",
    fav_food: "{{param1}} loved to eat {{fav_food}}. ",
    fav_sports: "{{param1}} enjoyed playing {{fav_sports}}. ",
    fav_books: "{{param1}} favorite books were {{fav_books}}. ",
    fav_music: "{{param1}} loved listening to {{fav_music}} music genres.",
    first_name: "First name",
    last_name: "Last name",
    nickname: "Family name",
    lg_url: "Lifograf handle",
    deceased: "Deceased",
    gender: "Gender",
    basic_add_details: "Additional Details",
    dob: "D.O.B",
    birth_time: "Birth Time",
    dod: "D.O.D,",
    native_language: "Native Language",
    religion: "Religion",
    nation: "Nationality",
    birth_cntry: "Birth Country",
    anc_region: "Ancestor Region",
    last_res: "Last Residence",
    dth_loc: "Death Location",
    death_cause: "Death Cause",
    race: "Race",
    caste: "Caste",
    clan: "Clan",
    star: "Star",
    mortal_remains: "Mortal Remains",
    mortal_remains_location: "Mortal Remains Location",
    pers_add_details: "Additional Details",
    edu_lvl: "Education Level",
    edu_qual: "Educational Qualification",
    schools: "Schools",
    colleges: "Colleges",
    edu_area: "Educational Area",
    edu_details: "Additional Details",
    main_occupation: "Main Occupation",
    sec_occupation: "Secondary Occupation",
    workplaces: "Workplaces",
    occu_details: "additional Details",
    chronic_illness: "Chronic Illness",
    physical_activity: "Physical Activity",
    drinking: "Drinking",
    smoking: "Smoking",
    blood_group: "Blood Group",
    health_details: "Additional Details",
    best_qual: "Best Quality",
    worst_qual: "Worst Quality",
    hobbies: "Hobbies",
    fav_food: "Favourite Food",
    fav_sports: "Favourite Sports",
    fav_books: "Favourite books",
    fav_music: "Favourite music",
    persntly_add_details: "Additional Details",
  },
  createRelation: {
    parentHeading: "To Create {{param}} Parents:",
    parentP1: "Choose ‘Relationship’ as Mother / Father",
    parentP2: "Choose ‘Related To’ as your own name",
    grandParentsHeading: "To Create {{param}} Grandparents:",
    grandParentsP1: "Choose ‘Relationship’ as Mother / Father",
    grandParentsP2: "Choose ‘Related To’ as your Father / Mother’s name",
    grandParentsNote:
      "Note : You need to create your Parents first in order to create your Grandparents",
    siblingsHeading: "To Create {{param}} Own Siblings:",
    siblingsP1: "Choose ‘Relationship’ as Sister / Brother",
    siblingsP2: "Choose ‘Related To’ as your own name",
    siblingsNote:
      "Note: Siblings should have the same Father & Mother as you. DO NOT USE siblings to create Step-brother / Step-Sister",
    stepSiblingsHeading: "To Create {{param}} Step Siblings:",
    stepSiblingsP1: "Choose ‘Relationship’ as Son / Daughter",
    stepSiblingsP2: "Choose ‘Related To’ as your / Father",
    stepSiblingsNote:
      "Note: DO NOT USE Brother / Sister to create Step-brother / Step-Sister",
    uncleAuntHeading: "To Create {{param}} Uncle / Aunt:",
    uncleAuntP1: "Choose ‘Relationship’ as Brother / Sister",
    uncleAuntP2: "Choose ‘Related To’ as your Mother / Father",
    uncleAuntNote:
      "Note: Uncle / Aunt shold have the same Father & Mother as your parent. DO NOT use this to create Step-Uncle / Step-Aunt relations",
    spouseHeading: "To Create {{param}} Spouse:",
    spouseP1: "Choose ‘Relationship’ as Husband / Wife",
    spouseP2: "Choose ‘Related To’ as you own name",
    childrenHeading: "To Create {{param}} Children:",
    childrenP1: "Choose ‘Relationship’ as Son / Daughter",
    childrenP2: "Choose ‘Related To’ as you own name",
    childrenNote:
      "Note: Use this only for your own Children. Do not use this to create your Step Children",
    others: "To Create Other:",
    othersP1:
      "If you want to add any other Relative, you will need to invite them",
    grandChildrenHeading: "To Create {{param}} Grand Children:",
    grandChildrenP1: "Choose ‘Relationship’ as Son / Daughter",
    grandChildrenP2: "Choose ‘Related To’ as you Son / Daughter’s name",
    grandChildrenNote:
      "Note: Use this only for your own Children. Do not use this to create your Step Children",
    cousinHeading: "To Add Cousin:",
    cousinP1:
      "You cannot directly add a Cousin. You can relate only through your Parents / Grandparents (for 2nd level cousings). So",
    cousinP2: "Click on the ‘Add Relation’ on your Cousin’s Parent’s Lifograf",
    cousinP3: "Choose ‘Relationship’ as Brother / Sister",
    cousinP4: "Choose ‘Related To’ as your Father / Mother",
    cousinNote:
      "NOTE: If your Cousin’s parent’s Lifograf is not yet created, please request your Cousin to create the same",
    inlawsHeading: "To Add In-Laws as relatives (your spouse’s relatives):",
    inlawsP1:
      "Click on the ‘Add Relation’ in the Lifograf of your Brother-in-law / Sister-in-law / Father-in-law / Mother-in-Law",
    inlawsP2: "Choose ‘Relationship’ as Brother / Sister / Father / Mother",
    inlawsP3: "Choose ‘Related To’ as your Spouse",
    inlawsNote:
      "NOTE: If your Spouse is already connected to their family, then you will automatically be connected with all your in-laws",
    nieceNephewHeading: "To Add a Neice / Nephew:",
    nieceNephew:
      "You cannot directly relate as a Neice / Nephew. You will automatically be related once your relate to your brother / sister",
  },
  alert: {
    title: {
      bday_alert_title: "{{param1}}'s birthday",
      wday_alert_title: "{{param1}}'s Wedding day",
    },
    message: {
      bday_alert_msg: "{{param1}} birthday is approaching on {{param2}}",
      wday_alert_msg: "{{param1}}'s wedding day is approaching on {{param2}}",
    },
  },
  pathname: {
    "": "Home",
    explorer: "Family Tree",
    search: "Search",
    relationrequest: "Request's",
    alert: "Notifications",
    upgrade: "Subscriptions",
    about: "About",
    lifeEvents: "Life Event",
    frozenMoments: "Moments",
    achievements: "Achievements",
    loveAndWishes: "Love & Wishes",
    passDowns: "Pass Downs",
    anecdotes: "Anecdotes",
    announcements: "Announcements",
  },
};
