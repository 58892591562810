import React, { useState } from "react";
import Button from "@mui/material/Button";
import { CustomDialogSwipeUp } from "../../UI/CustomDialogSwipeUp";
import { t } from "i18next";
import { InvitePeople } from "../atoms/InvitePeople";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
export const InvitePeopleButton = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        startIcon={<MailOutlineIcon />}
        onClick={() => setOpen(true)}
        variant={"contained"}
      >
        {t("display.text.button.invite")}
      </Button>
      <CustomDialogSwipeUp
        open={open}
        close={() => setOpen(false)}
        title={t("display.text.text.invitePeople")}
      >
        <InvitePeople close={() => setOpen(false)} />
      </CustomDialogSwipeUp>
    </>
  );
};
